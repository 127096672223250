import * as React from 'react';
import type { BuilderComponentProps } from '@builder.io/react/dist/types/src/components/builder-component.component';
import { localeModels } from '../../../constants/locales/Models';
import { LocaleModels, ModelList } from '../../../types/localeTypes';
import { getSortedPageIndexProps } from '../../../util/builder/builderActions';
import ThumbnailText from '../../articles/ThumbnailText';

const DisplayTable = ({ type, locale }: { type: string; locale: string }) => {
  const [articles, setArticles] = React.useState<BuilderComponentProps[] | null>(null);

  const getArticles = async () => {
    const model = localeModels[locale as keyof LocaleModels].models[type as keyof ModelList];

    const data = await getSortedPageIndexProps(model);

    return data.props.pages;
  };

  React.useEffect(() => {
    getArticles().then((data) => {
      setArticles(data);
    });
  }, [type, locale]);

  return (
    <section
      id="nextSection"
      className="grid max-w-7xl grid-cols-1 gap-4 py-10 sm:grid-cols-2 lg:grid-cols-3 lg:py-20"
    >
      {articles?.map((article: any) => {
        if (!article?.data) return null;

        const { data, createdDate } = article;
        const formattedDate = createdDate
          ? new Date(createdDate).toLocaleDateString(locale, {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            })
          : null;

        return (
          <ThumbnailText
            key={data.url}
            title={data.title ?? ''}
            description={data.description ?? ''}
            url={data.url ?? '#'}
            date={formattedDate ?? ''}
          />
        );
      })}
    </section>
  );
};

export default DisplayTable;
