import * as React from 'react';
import { Button, Text } from '@dreamplan/ui';
import clsx from 'clsx';
import { PageLinkType } from '../../../types/commonTypes';
import ArrowDown from '../../common/ArrowDown';

const HeroArticlePage = (props: PageLinkType) => {
  const { contentHeader, contentSubHeader, contentDescription, navigation, buttonDescription } =
    props;

  return (
    <section
      className={clsx(
        '-mx-[32px] flex flex-col gap-y-16 py-16 lg:-mx-[96px] lg:py-24',
        'bg-secondary relative items-center justify-center px-8 text-black md:px-16 2xl:-mx-[128px] 2xl:px-32',
      )}
    >
      <article className="relative flex flex-col items-center justify-center gap-y-8 pt-20">
        <div className="space-y-3">
          <Text className="py-0 text-center font-bold uppercase tracking-wide text-gray-800">
            {contentHeader}
          </Text>
          <Text variant={'h1'} className="py-0 text-center md:whitespace-pre-line">
            {contentSubHeader}
          </Text>
        </div>
        <Text className="max-w-xl py-0 text-center font-sans lg:whitespace-pre-line">
          {contentDescription}
        </Text>
        {!buttonDescription || (
          <Button asChild className="px-8 py-4 font-semibold" variant="primary" size="large">
            <a href={navigation}>{buttonDescription}</a>
          </Button>
        )}
      </article>
      <ArrowDown
        height={32}
        alt={'arrow pointing down'}
        aria-hidden={'true'}
        className={'hidden md:flex'}
      />
    </section>
  );
};

export default HeroArticlePage;
