import * as React from 'react';
import { useTranslation } from '@dreamplan/i18n';
import Head from 'next/head';

export type PageAttributes = {
  title?: string;
  description?: string;
  image?: string;
  canonical?: string;
  type?: string;
  relativeUrlPath?: string;
  children?: React.ReactNode;
};

export function PageHeader({
  description,
  image,
  title,
  canonical,
  children,
  type = 'website',
  relativeUrlPath,
}: PageAttributes) {
  const { lang } = useTranslation('navigation');
  const DEFAULT_TITLE = 'Dreamplan';

  return (
    <Head>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1, viewport-fit=cover, user-scalable=no, shrink-to-fit=no"
        key="viewport"
      />
      <meta property="og:locale" content={lang || 'da'} />

      {/* Icons */}
      <link rel="apple-touch-icon" sizes="180x180" href="/logo/favicon/apple-touch-icon.png" />
      <link rel="icon" type="image/x-icon" href="/logo/favicon/favicon.ico"></link>
      <link rel="icon" type="image/png" sizes="32x32" href="/logo/favicon/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/logo/favicon/favicon-16x16.png" />
      <link rel="manifest" href="/logo/favicon/site.webmanifest" />
      <link rel="mask-icon" href="/logo/dp-logo.svg" color="#5bbad5" />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="theme-color" content="#ffffff" />

      <meta name="HandheldFriendly" content="true" />

      <title>{title ?? DEFAULT_TITLE}</title>
      {description && <meta property="description" content={description} />}

      <meta property="og:title" content={title ?? DEFAULT_TITLE} />
      {description && (
        <>
          <meta property="og:description" content={description} />
          <meta name="description" content={description} />
        </>
      )}
      <meta property="og:type" content={type} />
      <meta property="og:image" content={image ?? '/illustrations/flying.png'} />
      {relativeUrlPath && (
        <meta property="og:url" content={`https://www.dreamplan.io/${relativeUrlPath}`} />
      )}

      {canonical && <link rel="canonical" href={canonical} />}

      {children}
    </Head>
  );
}
