import * as React from 'react';

const ArticleContent = ({ article, date }: { article: string; date?: string }) => {
  return (
    <section id="nextSection" className="break-words bg-white md:px-8 lg:px-0 2xl:px-32">
      {date ? <span className="text-xs">{`Publiceret d. ${date}`}</span> : null}
      <div dangerouslySetInnerHTML={{ __html: article }}></div>
    </section>
  );
};

export default ArticleContent;
