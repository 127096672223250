import { SocialInfo } from '../types/commonTypes';

export const socialInfo: SocialInfo = {
  linkedIn: 'https://www.linkedin.com/company/dreamplan',
  facebook: 'https://www.facebook.com/DreamPlan.io',
  instagram: 'https://www.instagram.com/dreamplan.dk',
  app: (type: 'sign-in' | 'sign-up', locale: string = 'da'): string => {
    const paths = {
      'sign-in': '/auth/sign-in',
      'sign-up': '/signup',
    };
    return `/${locale}${paths[type]}`;
  },
};
