import * as React from 'react';
import { useTranslation } from '@dreamplan/i18n';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import Link from 'next/link';
import { IconType } from '../../types/commonTypes';

const BackButton = (props: IconType) => {
  const { width, url } = props;
  const { t } = useTranslation('navigation');
  return (
    <Link href={`${url}`} aria-hidden={'true'} aria-label={t('back_button_aria_label')}>
      <ChevronLeftIcon width={width} strokeWidth={2.35} />
    </Link>
  );
};

export default BackButton;
