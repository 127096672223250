import { Builder, withChildren } from '@builder.io/react';
import {
  TextContentBlock,
  defaultValues,
  gapOptions,
  widthOptions,
  paddingOptions,
  colorOptions,
} from './TextContentBlock';

export const registerTextContentBlock = () => {
  const inputField = (
    name: string,
    type: string,
    defaultValue: any,
    friendlyName?: string,
    helperText?: string,
    subFields?: any[],
    options?: any,
  ) => ({
    name,
    type,
    defaultValue,
    friendlyName,
    helperText,
    subFields,
    ...options,
  });

  const subField = (name: string, type: string, defaultValue: any, enumOptions?: any[]) => ({
    name,
    type,
    defaultValue,
    enum: enumOptions,
  });

  const RegisterTextContentBlock = withChildren(TextContentBlock);

  Builder.registerComponent(RegisterTextContentBlock, {
    name: 'TextContentBlock',
    inputs: [
      inputField('title', 'object', defaultValues.title, 'Title', undefined, [
        subField('text', 'richText', defaultValues.title.text),
        subField('size', 'select', defaultValues.title.size, [
          { label: 'H1', value: 'h1' },
          { label: 'H2', value: 'h2' },
          { label: 'H3', value: 'h3' },
          { label: 'H4', value: 'h4' },
          { label: 'H5', value: 'h5' },
          { label: 'H6', value: 'h6' },
        ]),
      ]),
      inputField('body', 'object', defaultValues.body, 'Body text', undefined, [
        subField('text', 'richText', defaultValues.body.text),
        subField('size', 'select', defaultValues.body.size, [
          { label: 'Large', value: 'large' },
          { label: 'Paragraph', value: 'paragraph' },
          { label: 'Default', value: 'default' },
          { label: 'Small', value: 'small' },
          { label: 'Xsmall', value: 'xsmall' },
        ]),
      ]),
      inputField('section', 'object', defaultValues.section, 'Section', undefined, [
        subField('gap', 'select', defaultValues.section.gap, Object.keys(gapOptions)),
        subField('width', 'select', defaultValues.section.width, Object.keys(widthOptions)),
        subField('padding', 'select', defaultValues.section.padding, Object.keys(paddingOptions)),
        subField(
          'backgroundColor',
          'select',
          defaultValues.section.backgroundColor,
          Object.keys(colorOptions),
        ),
      ]),
      inputField(
        'includedChildren',
        'array',
        [{ blocks: [] }],
        undefined,
        undefined,
        [subField('blocks', 'blocks', [])],
        {
          hideFromUI: true,
        },
      ),
    ],
  });
};
