import { BuilderComponentProps } from '@builder.io/react/dist/types/src/components/builder-component.component';
import { builder } from '@builder.io/sdk';
import { UserAttributes } from '@builder.io/sdk/dist/src/builder.class';
import builderConfig from '../../config/builderConfig';

builder.init(builderConfig.apiKey, false);

export const resolveBuilderContent = async (
  path: string[],
  locale: string,
  userAttributes: UserAttributes,
) => {
  const model = path.length === 1 ? 'page' : path[path.length - 2] ?? 'page';

  const page = await builder
    .get(model, {
      userAttributes,
      includeRefs: true,
    })
    .toPromise();

  return {
    props: {
      page: page || null,
      model,
      locale,
    },
    revalidate: builderConfig.revalidation,
  };
};

export const getSortedPageIndexProps = async (model: string) => {
  const pages: BuilderComponentProps[] = await builder.getAll(model, {
    fields: 'createdDate,data',
    includeRefs: true,
    options: {
      noTargeting: true,
      sort: {
        createdDate: -1,
      },
    },
  });
  return {
    props: {
      pages,
    },
    revalidate: builderConfig.revalidation,
  };
};
