import { Pages } from '@dreamplan/ui/types/Locales';

export const otherPages: Pages = {
  da: [
    {
      page: 'Hvordan regner dreamplan?',
      navigation: '/hvordan',
    },
    {
      page: 'Spørgsmål og Svar',
      navigation: '/spoergsmaal-og-svar',
    },
    {
      page: 'Kundepanel',
      navigation: '/kundepanel',
    },
    {
      page: 'Brugervilkår',
      navigation: '/vilkaar',
    },
    {
      page: 'Handelsbetingelser ',
      navigation: '/handelsbetingelser',
    },
    {
      page: 'Persondatapolitik',
      navigation: '/privatlivspolitik',
    },
    {
      page: 'Cookies og privatlivspolitik ',
      navigation: '/cookies-og-privatlivspolitk',
    },
    {
      page: 'Beregninger',
      navigation: '/beregninger',
    },
  ],
  en: [
    {
      page: 'How does dreamplan calculate?',
      navigation: '/how',
    },
    {
      page: 'Questions and Answers',
      navigation: '/questions-and-answers',
    },
    {
      page: 'Customer Panel',
      navigation: '/customer-panel',
    },
    {
      page: 'Terms',
      navigation: '/terms',
    },
    {
      page: 'Terms and Conditions',
      navigation: '/terms-and-conditions',
    },
    {
      page: 'Data Policy',
      navigation: '/data-policy',
    },
    {
      page: 'Cookies and Data Policy ',
      navigation: '/cookies-and-data-policy',
    },
    {
      page: 'Calculations',
      navigation: '/calculations',
    },
  ],
  es: [
    {
      page: '¿Cómo calcula dreamplan?',
      navigation: '/como',
    },
    {
      page: 'Preguntas y Respuestas',
      navigation: '/preguntas-y-respuestas',
    },
    {
      page: 'Panel del Cliente',
      navigation: '/panel-del-cliente',
    },
    {
      page: 'Términos',
      navigation: '/terminos',
    },
    {
      page: 'Condiciones Generales',
      navigation: '/condiciones-generales',
    },
    {
      page: 'Política de Datos',
      navigation: '/politica-de-datos',
    },
    {
      page: 'Política de Cookies y Datos ',
      navigation: '/politica-de-cookies-y-datos',
    },
    {
      page: 'Cálculos',
      navigation: '/calculos',
    },
  ],
  no: [
    {
      page: 'Hvordan beregner dreamplan?',
      navigation: '/hvordan',
    },
    {
      page: 'Spørsmål og svar',
      navigation: '/spoergsmaal-og-svar',
    },
    {
      page: 'Kundepanel',
      navigation: '/kundepanel',
    },
    {
      page: 'Vilkår',
      navigation: '/vilkaar',
    },
    {
      page: 'Vilkår og betingelser',
      navigation: '/vilkaar-og-betingelser',
    },
    {
      page: 'Datapolitikk',
      navigation: '/datapolitikk',
    },
    {
      page: 'Cookies og datapolitikk ',
      navigation: '/cookies-og-datapolitikk',
    },
    {
      page: 'Beregninger',
      navigation: '/beregninger',
    },
  ],
};
