import { Builder } from '@builder.io/react';
import { registerHero } from './components/Hero/Hero.builder';
import { registerTextAndImage } from './components/TextAndImage/TextAndImage.builder';
import { registerTextContentBlock } from './components/TextContentBlock/TextContentBlock.builder';

export const registerNewBuilderInserts = () => {
  registerTextAndImage();
  registerHero();
  registerTextContentBlock();

  Builder.register('insertMenu', {
    name: 'New Website - Custom Components',
    items: [
      // Component with Text and Image
      { name: 'Text and Image', item: 'TextAndImage' },
      // Hero
      { name: 'Hero', item: 'Hero' },
      // TextContentBlock
      { name: 'TextContentBlock', item: 'TextContentBlock' },
    ],
  });
};
