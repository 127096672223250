import { Builder } from '@builder.io/react';
import TextAndImage, { defaultValues } from './TextAndImage';

export const registerTextAndImage = () => {
  const colorOptions = [
    { label: 'None', value: 'none' },
    { label: 'Primary', value: 'bg-primary' },
    { label: 'Secondary', value: 'bg-secondary' },
    { label: 'Tertiary', value: 'bg-tertiary' },
    { label: 'Primary Light', value: 'bg-primary-light' },
    { label: 'Secondary Light', value: 'bg-secondary-light' },
    { label: 'Tertiary Light', value: 'bg-tertiary-light' },
    { label: 'Primary Dark', value: 'bg-primary-dark' },
  ];

  const inputField = (
    name: string,
    type: string,
    defaultValue: any,
    subFields?: any[],
    options?: any,
  ) => ({
    name,
    type,
    defaultValue,
    subFields,
    ...options,
  });

  const subField = (name: string, type: string, defaultValue: any, enumOptions?: any[]) => ({
    name,
    type,
    defaultValue,
    enum: enumOptions,
  });

  Builder.registerComponent(TextAndImage, {
    name: 'TextAndImage',
    inputs: [
      inputField('title', 'object', defaultValues.title, [
        subField('text', 'richText', defaultValues.title.text),
        subField('size', 'radio', defaultValues.title.size, [
          { label: 'h1', value: 'h1' },
          { label: 'h3', value: 'h3' },
        ]),
      ]),
      inputField('subtitle', 'string', defaultValues.subtitle, undefined, {
        helperText: 'Leave blank in in order to hide the subtitle',
      }),
      inputField('body', 'object', defaultValues.body, [
        subField('text', 'richText', defaultValues.body.text),
        subField('size', 'radio', defaultValues.body.size, [
          { label: 'Large', value: 'large' },
          { label: 'Paragraph', value: 'paragraph' },
        ]),
      ]),
      inputField('mail', 'email', defaultValues.mail, undefined, {
        helperText: 'Leave blank in order to hide the mail address',
      }),
      inputField('icon', 'string', defaultValues.icon, undefined, {
        helperText: 'Leave blank in order to hide the icon',
      }),
      inputField('image', 'object', defaultValues.image, [
        subField('color', 'string', defaultValues.image.color, colorOptions),
        subField('placement', 'radio', defaultValues.image.placement, [
          { label: 'Left', value: 'left' },
          { label: 'Right', value: 'right' },
        ]),
        subField('mirror', 'boolean', defaultValues.image.mirror),
      ]),
      inputField('sectionColor', 'string', defaultValues.sectionColor, undefined, {
        enum: colorOptions,
      }),
      inputField(
        'includedChildren',
        'array',
        defaultValues.includedChildren,
        [
          subField('firstChild', 'array', [{ item: [] }]),
          subField('secondChild', 'array', [{ item: [] }]),
        ],
        { hideFromUI: true },
      ),
    ],
  });
};
