import { Info } from '../../types/localeTypes';

export const info: Info = {
  da: {
    address: 'Applebys Pl. 7, 1411 København',
    email: 'kundeservice@dreamplan.io',
    phone: '+ 45 2613 6195',
    followText: 'Følg os på sociale medier',
  },
  en: {
    address: 'Applebys P. 7, DK-1411 Copenhagen, Denmark',
    email: 'customerservice@dreamplan.io',
    phone: '+4526 13 6195',
    followText: 'Follow us on social media',
  },
  es: {
    address: 'Applebys P. 7, DK-1411 Copenhague, Dinamarca',
    email: 'customerservice@dreamplan.io',
    phone: '+4526 13 6195',
    followText: 'Síguenos en las redes sociales',
  },
  no: {
    address: 'Applebys P. 7, DK-1411 København, Danmark',
    email: 'customerservice@dreamplan.io',
    phone: '+4526 13 6195',
    followText: 'Følg oss på sosiale medier',
  },
};
