import * as React from 'react';
import { Button, Text } from '@dreamplan/ui';
import { CallToActionProps } from '../../../types/commonTypes';

const CallToActionText = ({ title, text, link, label }: CallToActionProps) => {
  return (
    <section className="flex flex-col items-center justify-center gap-y-7 py-24">
      <div>
        <Text variant={'h2'} className="p-0 text-center">
          {title}
        </Text>
        <Text className="mt-2 p-0 text-center">{text}</Text>
      </div>
      <Button asChild variant="secondary">
        <a href={link}>{label}</a>
      </Button>
    </section>
  );
};

export default CallToActionText;
