import * as React from 'react';
import { socialInfo } from '../../../constants/info';
import FacebookIcon from '../../svgs/FacebookIcon';
import InstagramIcon from '../../svgs/InstagramIcon';
import LinkedinIcon from '../../svgs/LinkedinIcon';

type SocialMediaType = 'linkedin' | 'facebook' | 'instagram';

const SocialMedia = (props: {
  size: number;
  className?: string;
  type: SocialMediaType;
  href?: string;
}) => {
  const { size, className, type, href } = props;

  switch (type) {
    case 'linkedin':
      return (
        <a
          href={href ?? socialInfo.linkedIn}
          target="_blank"
          rel="noreferrer"
          aria-label="Linkedin"
        >
          <LinkedinIcon width={size} height={size} className={className} aria-hidden={'false'} />
        </a>
      );
    case 'facebook':
      return (
        <a
          href={href ?? socialInfo.facebook}
          target="_blank"
          rel="noreferrer"
          aria-label="Facebook"
        >
          <FacebookIcon width={size} height={size} className={className} aria-hidden={'false'} />
        </a>
      );
    case 'instagram':
      return (
        <a
          href={href ?? socialInfo.instagram}
          target="_blank"
          rel="noreferrer"
          aria-label="Instagram"
        >
          <InstagramIcon width={size} height={size} className={className} aria-hidden={'false'} />
        </a>
      );
    default:
      return null;
  }
};

export default SocialMedia;
