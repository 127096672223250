'use client';
import * as React from 'react';
import { BuilderBlocks, BuilderElement } from '@builder.io/react';
import { Text } from '@dreamplan/ui';
import { VariantProps } from 'class-variance-authority';
import clsx from 'clsx';

export const defaultValues = {
  title: {
    text: 'Lorem <u>ipsum</u> dolor',
    size: 'h2' as VariantProps<typeof Text>['variant'],
  },
  subtitle: 'Lorem ipsum',
  body: {
    text: 'Lorem ipsum dolor sit <strong>amet</strong> consectetur adipiscing elit, sed do <strong>eiusmod</strong> tempor incididunt utlabore et dolore <u>magna aliqua</u>. Ut enim ad <u>minim veniam</u>, quis <strong>nostrud</strong> exercitation ullamcolaboris nisi ut <strong>aliquip</strong> ex eacommodo consequat.',
    size: 'large' as VariantProps<typeof Text>['variant'],
  },
  section: {
    gap: 'Small' as SupportedGaps,
    width: 'Small' as SupportedWidths,
    padding: 'Default' as SupportedPadding,
    backgroundColor: 'Secondary' as SupportedColors,
  },
};

export const colorOptions = {
  None: ['none', 'none'],
  Primary: ['bg-primary', 'text-on-primary'],
  Secondary: ['bg-secondary', 'text-on-secondary'],
  Tertiary: ['bg-tertiary', 'text-on-tertiary'],
  'Primary Light': ['bg-primary-light', 'text-on-primary-light'],
  'Secondary Light': ['bg-secondary-light', 'text-on-secondary-light'],
  'Tertiary Light': ['bg-tertiary-light', 'text-on-tertiary-light'],
  'Primary Dark': ['bg-primary-dark', 'text-on-primary-dark'],
} as const;
export type SupportedColors = keyof typeof colorOptions;

export const gapOptions = {
  Small: 'space-y-2 md:space-y-6',
  Large: 'space-y-6 md:space-y-8',
} as const;
export type SupportedGaps = keyof typeof gapOptions;

export const widthOptions = {
  Small: 'w-10/12 md:w-7/12 md:max-w-4xl',
  Large: 'w-10/12 md:w-9/12 md:max-w-4xl',
} as const;
export type SupportedWidths = keyof typeof widthOptions;

export const paddingOptions = {
  None: 'py-0 md:py-0',
  Default: 'py-10 md:py-20',
} as const;
export type SupportedPadding = keyof typeof paddingOptions;

export function TextContentBlock({
  title = defaultValues.title,
  body = defaultValues.body,
  section = defaultValues.section,
  builderBlock,
  includedChildren,
}: {
  title: {
    text: string;
    size: VariantProps<typeof Text>['variant'];
  };
  body: {
    text: string;
    size: VariantProps<typeof Text>['variant'];
  };
  section: {
    gap: SupportedGaps;
    width: SupportedWidths;
    padding: SupportedPadding;
    backgroundColor: SupportedColors;
  };
  builderBlock?: BuilderElement;
  includedChildren?: any;
}) {
  return (
    <section>
      <div
        className={clsx(
          colorOptions[section.backgroundColor] ??
            colorOptions[defaultValues.section.backgroundColor],
          paddingOptions[section.padding] ?? paddingOptions[defaultValues.section.padding],
          'items-center justify-center',
        )}
      >
        <div
          className={clsx(
            gapOptions[section.gap] ?? gapOptions[defaultValues.section.gap],
            widthOptions[section.width] ?? widthOptions[defaultValues.section.width],
            'mx-auto flex-col items-center text-center',
          )}
        >
          {/* Texts */}
          <div className={clsx(gapOptions[section.gap] ?? gapOptions[defaultValues.section.gap])}>
            <Text asHTML variant={title.size} tag="h1">
              {title.text}
            </Text>
            <Text asHTML variant={body.size} tag="p">
              {body.text}
            </Text>
          </div>
          {/* BuilderBlocks */}
          <div
            className={clsx(
              gapOptions[section.gap] ?? gapOptions[defaultValues.section.gap],
              'flex h-auto justify-center',
            )}
          >
            {includedChildren?.map((child: any, index: any) => (
              <BuilderBlocks
                child
                key={index}
                parentElementId={builderBlock?.id}
                dataPath={`component.options.includedChildren.${index}.blocks`}
                blocks={child.blocks}
                className="w-fit text-center"
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
