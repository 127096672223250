import * as React from 'react';
import { StarIcon, ArrowLongRightIcon } from '@heroicons/react/20/solid';
import { Carousel } from 'react-responsive-carousel';

interface ArrowProps {
  clickHandler: () => void;
  has: boolean;
  type: string;
  className?: string;
}
interface ReviewProps {
  reviewRating: number;
  reviewText: string;
  reviewAuthor: string;
}
interface ReviewTableProps {
  reviews: ReviewProps[];
}

const Star = () => {
  return <StarIcon width="21" height="20" fill="#1A2C2D" />;
};

const Arrow = ({ clickHandler, has, type, className }: ArrowProps) => {
  return (
    <button
      onClick={has ? clickHandler : () => null}
      className={`z-10 w-[31px] ${!has ? 'cursor-default' : null} ${className}`}
    >
      <ArrowLongRightIcon
        className={`${!has ? 'hidden' : null}`}
        transform={type === 'previous slide / item' ? `rotate(180)` : ''}
        stroke="#BEC1CA"
        width="31"
        height="15"
      />
    </button>
  );
};

const Review = ({ reviewAuthor, reviewRating, reviewText }: ReviewProps) => {
  return (
    <div className="flex items-center justify-center p-2 pb-44 md:mx-10 lg:mx-20">
      <div className="xs:p-5 md:w-100 flex h-80 w-full max-w-full items-center justify-center rounded-2xl bg-sky-50 sm:p-10 md:p-0">
        <ul className="text-center sm:px-5 md:px-20 md:pb-8">
          <li className="mb-8 mt-2 flex justify-center">
            {Array(reviewRating)
              .fill(undefined)
              .map((e, i) => (
                <Star key={i} />
              ))}
          </li>
          <li className="line-clamp-6 md:line-clamp-none">{reviewText}</li>
          <li className="mt-6 text-xl font-bold md:mt-8">{reviewAuthor}</li>
        </ul>
      </div>
    </div>
  );
};

const ReviewTable = (props: ReviewTableProps) => {
  const { reviews } = props;

  return (
    <div className="flex justify-center">
      <Carousel
        className="max-w-full sm:w-5/6"
        showStatus={false}
        renderArrowPrev={(clickHandler, hasPrev, label) => (
          <Arrow
            className="hidden md:block"
            clickHandler={clickHandler}
            has={hasPrev}
            type={label}
          />
        )}
        renderArrowNext={(clickHandler, hasNext, label) => (
          <Arrow
            className="hidden md:block"
            clickHandler={clickHandler}
            has={hasNext}
            type={label}
          />
        )}
      >
        {reviews.map((review) => (
          <Review key={review.reviewAuthor} {...review} />
        ))}
      </Carousel>
    </div>
  );
};

export default ReviewTable;
