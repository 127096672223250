'use client';
import * as React from 'react';
import { Button, useLocale, Text } from '@dreamplan/ui';
import clsx from 'clsx';
import { socialInfo } from '../../../constants/info';
import { PageLinkType } from '../../../types/commonTypes';
import ArrowDown from '../../common/ArrowDown';

const HeroMain = (props: PageLinkType) => {
  const { contentSubHeader, contentDescription, className, navigation, buttonDescription } = props;
  const locale = useLocale();

  return (
    <section
      className={clsx(
        'mt-10 flex min-h-screen flex-col gap-y-20 md:mt-0 md:min-h-full md:gap-y-24 lg:gap-y-0',
        '-mx-[32px] px-8 md:h-[700px] md:px-16 lg:-mx-[96px] 2xl:-mx-[128px] 2xl:px-32',
        'xs:pb-10 bg-secondary relative items-center justify-center pb-20 text-black',
      )}
    >
      <div className="xs:gap-y-3 relative flex flex-col items-center gap-y-10 pt-20 md:grid md:grid-cols-2 lg:max-w-7xl">
        <Text
          variant={'h1'}
          className="py-0 text-center leading-10 md:self-end md:text-left lg:pr-10"
        >
          {contentSubHeader}
        </Text>

        <div className="xs:h-60 xs:w-60 relative self-center md:row-span-2 md:h-80 md:w-80 md:justify-self-end lg:h-96 lg:w-96 2xl:h-[440px] 2xl:w-[440px]">
          <img
            src={navigation}
            alt="image-hero"
            className=" xs:w-60  md:w-80  lg:w-96 2xl:w-[480px] "
          />
        </div>

        <div
          className={`${className} flex flex-col items-center justify-center gap-y-7 md:items-start`}
        >
          <Text variant={'large'} className="py-0 text-center md:text-left lg:pr-16">
            {contentDescription}
          </Text>
          <Button asChild variant="primary">
            <a href={socialInfo.app('sign-up', locale)}>{buttonDescription}</a>
          </Button>
        </div>
      </div>
      <ArrowDown
        height={32}
        alt={'arrow pointing down'}
        aria-hidden={'true'}
        className={'hidden md:flex'}
      />
    </section>
  );
};

export default HeroMain;
