import * as React from 'react';
import { useTranslation } from '@dreamplan/i18n';
import Link from 'next/link';
import { ContactInfo, PageLinkType, SocialInfo } from '../../types/CommonTypes';
import Contact from '../Contact';
import { LicencesInformation, SupportedLocales } from '../HeaderWhite';
import Logo from '../Logo';
import PageLink from '../PageLink';
import { Text } from '../Typography';

export interface FooterProps {
  links?: PageLinkType[];
  info: ContactInfo;
  socials: Omit<SocialInfo, 'app'>;
}

const Footer = (props: FooterProps) => {
  const { links = [], info, socials } = props;
  const { lang } = useTranslation('navigation');

  const { link, text } = LicencesInformation[(lang || 'en') as SupportedLocales];
  return (
    <div className="bg-tertiary w-full">
      <footer className="items-left font-alternative 3xl:w-9/12 3xl:m-auto max-w-screen-3xl bottom-0 flex min-h-[50px] flex-col justify-center px-8 py-20 text-base text-white lg:px-24 2xl:px-32">
        <Logo fill={'white'} width={85} height={78} className="mb-5" />
        <article className="flex flex-col gap-y-20 md:flex-row md:justify-between">
          <Contact
            info={info}
            socials={socials}
            iconColor="white"
            className="flex flex-col gap-y-14 md:justify-between"
          />
          <ul className="m-0 flex list-none flex-col gap-y-5 p-0" id="footer">
            {links.map((link, index) => (
              <li key={index}>
                <PageLink link={link} />
              </li>
            ))}
          </ul>
        </article>
        <Text className="mt-20 self-center text-center">
          {text[0] + ' '}
          <Link href={link[0]} target={'_blank'} rel="noreferrer" className="underline">
            FTID 35166
          </Link>
          {' ' + text[1] + ' '}
          <Link href={link[1]} target={'_blank'} rel="noreferrer" className="underline">
            FTID 43055
          </Link>
          .
          <br /> &copy; Copyright Dreamplan.io
        </Text>
      </footer>
    </div>
  );
};

export default Footer;
