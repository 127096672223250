import { HeaderList } from '../../types/localeTypes';

export const headerList: HeaderList = {
  da: {
    menu: 'Menu',
    other: 'Andet',
    contact: 'Kontakt',
  },
  en: {
    menu: 'Menu',
    other: 'Other',
    contact: 'Contact',
  },
  es: {
    menu: 'Menú',
    other: 'Otro',
    contact: 'Contacto',
  },
  no: {
    menu: 'Meny',
    other: 'Annet',
    contact: 'Kontakt',
  },
};
