import * as React from 'react';
import { Text } from '@dreamplan/ui';
import { clsx } from 'clsx';
import { PageLinkType } from '../../../types/commonTypes';
import ArrowDown from '../../common/ArrowDown';
import BackButton from '../../common/BackButton';

const HeroSecondary = (props: PageLinkType) => {
  const { contentHeader, contentSubHeader, contentDescription, color, navigation } = props;

  return (
    <section
      className={clsx(
        '-mx-[32px] flex h-screen flex-col gap-y-20 md:h-[700px] md:gap-y-24 lg:-mx-[96px]',
        `bg-${color} items-center justify-center px-8 text-black md:px-16 lg:px-48  2xl:-mx-[128px] 2xl:px-96`,
      )}
    >
      <div className="flex flex-col gap-y-7 pt-20">
        <BackButton width={22} url={navigation} />
        <div className="flex flex-col items-center">
          <Text tag="h2" className="py-0 text-center font-sans font-medium uppercase">
            {contentHeader}
          </Text>
          <Text variant={'h3'} className="py-0 text-center">
            {contentSubHeader}
          </Text>
        </div>
        <Text className="py-0 text-center">{contentDescription}</Text>
      </div>
      <ArrowDown height={32} aria-hidden={'true'} className={'hidden md:flex'} />
    </section>
  );
};

export default HeroSecondary;
