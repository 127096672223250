'use client';
import * as React from 'react';
import { BuilderBlocks, BuilderElement } from '@builder.io/react';
import { Text } from '@dreamplan/ui';
import clsx from 'clsx';

export const defaultValues = {
  title: 'Lets plan your finances together in a language you understand',
  body: 'Do you dream of mastering your finances without being confused by complex numbers? Dreamplan is here to help you create a clear path forward! Take control of your finances and achieve your dreams with our easy-to-use solution and financial advice that speaks your language!',

  image: {
    small:
      'https://cdn.builder.io/api/v1/image/assets%2Fcd2a22d169224f24a85622358a6d2402%2Fb1f408f6b89443deb53390509d6ed2c9',
    large:
      'https://cdn.builder.io/api/v1/image/assets%2Fcd2a22d169224f24a85622358a6d2402%2F7a4012c37add4b219ecc10cae02622f9',
    alt: 'Lorem ipsum',
  },
  backgroundColor: 'bg-tertiary' as SupportedColors,
};

export const colorMap = {
  'bg-primary': 'text-on-primary',
  'bg-secondary': 'text-on-secondary',
  'bg-tertiary': 'text-on-tertiary',
  'bg-primary-light': 'text-on-primary-light',
  'bg-secondary-light': 'text-on-secondary-light',
  'bg-tertiary-light': 'text-on-tertiary-light',
  'bg-primary-dark': 'text-on-primary-dark',
} as const;
export type SupportedColors = keyof typeof colorMap;

export function Hero({
  title = defaultValues.title,
  body = defaultValues.body,
  image = defaultValues.image,
  backgroundColor = defaultValues.backgroundColor,
  builderBlock,
  includedChildren,
}: {
  title: string;
  body: string;
  image: {
    small: string;
    large: string;
    alt: string;
  };
  backgroundColor: SupportedColors;
  builderBlock?: BuilderElement;
  includedChildren?: any;
}) {
  return (
    <section>
      <div>
        <div
          className={clsx(
            // Changes text color depending background color
            colorMap[backgroundColor] ?? colorMap[defaultValues.backgroundColor],
            // Background color
            backgroundColor,
            'items-center justify-center space-y-6 pb-56 pt-28 sm:space-y-8 lg:pb-80',
          )}
        >
          {/* Texts */}
          <div className="mx-auto flex w-10/12 flex-col items-center space-y-6 text-center sm:space-y-8 md:w-9/12 md:max-w-4xl">
            <Text asHTML variant="h1" className="leading-9 sm:leading-10 lg:leading-[3rem]">
              {title}
            </Text>
            <Text asHTML variant="default" tag="p" className="leading-6">
              {body}
            </Text>
          </div>
          {/* BuilderBlocks */}
          <div className="flex h-auto justify-center">
            {includedChildren?.map((child: any, index: any) => (
              <BuilderBlocks
                child
                key={index}
                parentElementId={builderBlock?.id}
                dataPath={`component.options.includedChildren.${index}.blocks`}
                blocks={child.blocks}
                className="w-fit space-y-6 text-center sm:space-y-8"
              />
            ))}
          </div>
        </div>
      </div>
      {/* Image */}
      <div className="relative mx-auto -mt-56 h-fit w-2/3 lg:-mt-80 2xl:w-1/3">
        <picture>
          <source media="(max-width: 640px)" srcSet={image.small} />
          <source media="(min-width: 640px)" srcSet={image.large} />
          <img src={image.large} alt={image.alt} className="h-fit w-full" />
        </picture>
      </div>
    </section>
  );
}
