'use client';
import * as React from 'react';
import { useLocale, Text } from '@dreamplan/ui';
import Image from 'next/legacy/image';
import { socialInfo } from '../../../constants/info';
import TwoAnnonceButtons from '../../annoncepages/TwoAnnonceButtons';

const AnnouncementContent = ({ title }: { title: string }) => {
  const locale = useLocale();
  return (
    <article className="flex flex-col justify-between md:flex-row md:place-items-center  md:gap-x-14">
      <div className="flex flex-col justify-center gap-y-10 md:w-1/2">
        <Text variant={'h2'} tag="p" className="self-start py-0 font-semibold text-zinc-800">
          {title}
        </Text>

        <div className="font-medium">
          <Text variant={'h5'} tag="p" className="pb-0">
            Din økonomi!
          </Text>
          <Text variant={'h5'} tag="p" className="pb-0">
            Dine muligheder!
          </Text>
          <Text variant={'h5'} tag="p" className="pb-0">
            i 3 lette trin:
          </Text>
        </div>

        <ol className=" list-decimal py-0 text-xl font-medium">
          <li>
            Vi <span className="font-bold">indsamler</span> dine data
          </li>
          <li>
            Vi <span className="font-bold">regner</span> på din situation
          </li>
          <li>
            Du <span className="font-bold">tilpasser</span> til dine drømme
          </li>
        </ol>

        <TwoAnnonceButtons
          link={socialInfo.app('sign-up', locale)}
          className={'border-yellow-300'}
          buttonDescription={'Ja tak til flere penge'}
          secondLink={'/'}
          secondClassName={'border-yellow-300'}
          secondButtonDescription={'Nej tak til flere penge'}
        />
      </div>

      <div className="xs:h-100 relative md:w-1/2 2xl:h-[415px] 2xl:w-[470px]">
        <Image
          src={'/illustrations/omløsningen.png'}
          layout="fill"
          objectFit="cover"
          quality={100}
          alt="Omløsningen"
        />
      </div>
    </article>
  );
};

export default AnnouncementContent;
