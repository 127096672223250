import { Builder } from '@builder.io/react';
import AnnouncementContent from '../../../components/builder/content/AnnouncementContent';
import ArticleContent from '../../../components/builder/content/ArticleContent';

const registerContentInserts = () => {
  Builder.registerComponent(ArticleContent, {
    name: 'ArticleContent',
    inputs: [
      { name: 'date', type: 'string' },
      { name: 'article', type: 'richText' },
    ],
  });
  Builder.registerComponent(AnnouncementContent, {
    name: 'AnnouncementContent',
    inputs: [
      {
        name: 'title',
        type: 'string',
        defaultValue: 'Du bliver rigere af et overblik',
      },
    ],
  });
};

export default registerContentInserts;
