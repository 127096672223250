import { Builder } from '@builder.io/react';
import { Button, Text } from '@dreamplan/ui';

const registerCommonInserts = () => {
  Builder.registerComponent(Button, {
    name: 'Button',
    inputs: [
      {
        name: 'variant',
        type: 'string',
        defaultValue: 'primary',
        enum: [
          'primary',
          'secondary',
          'link',
          'outlined',
          'outlined-inverted',
          'outlined-stripped',
          'text',
        ],
      },
      {
        name: 'size',
        type: 'string',
        defaultValue: 'large',
        enum: ['small', 'large', 'full', 'none'],
      },
      { friendlyName: 'Button', name: 'children', type: 'string', defaultValue: 'Button' },
    ],
  });

  Builder.registerComponent(Text, {
    name: 'Typography',
    inputs: [
      {
        name: 'variant',
        type: 'string',
        defaultValue: 'default',
        enum: [
          'h1',
          'h2',
          'h3',
          'h4',
          'h5',
          'h6',
          'paragraph',
          'large',
          'default',
          'small',
          'xsmall',
        ],
      },
      {
        name: 'tag',
        type: 'string',
        defaultValue: 'p',
        enum: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'span'],
      },
      {
        name: 'size',
        type: 'string',
        defaultValue: 'large',
        enum: ['small', 'large', 'full', 'none'],
      },
      { friendlyName: 'Typography', name: 'children', type: 'string', defaultValue: 'Typography' },
    ],
  });
};

export default registerCommonInserts;
