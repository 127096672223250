import * as React from 'react';
import { Button } from '@dreamplan/ui';
import { TwoButtonsType } from '../../types/commonTypes';

const TwoAnnonceButtons = ({
  className,
  link,
  buttonDescription,
  secondClassName,
  secondLink,
  secondButtonDescription,
}: TwoButtonsType) => {
  return (
    <div className="flex gap-x-2">
      <Button asChild variant="primary" className={`${className} w-[160px] border-4`}>
        <a href={link}>{buttonDescription}</a>
      </Button>
      <Button asChild variant="secondary" className={`${secondClassName} w-[160px] border-4`}>
        <a href={secondLink}>{secondButtonDescription}</a>
      </Button>
    </div>
  );
};

export default TwoAnnonceButtons;
