import { Builder } from '@builder.io/react';
import registerBuilderActions from './builderInserts/registerActionInserts';
import registerCommonInserts from './builderInserts/registerCommonInserts';
import registerContentInserts from './builderInserts/registerContentInserts';
import registerHeroInserts from './builderInserts/registerHeroInserts';
import registerTableInserts from './builderInserts/registerTableInserts';

export const registerBuilderInserts = () => {
  registerBuilderActions();
  registerHeroInserts();
  registerCommonInserts();
  registerContentInserts();
  registerTableInserts();

  Builder.register('insertMenu', {
    name: 'Dreamplan Custom Components',
    items: [
      // Heroes
      { name: 'Hero Main', item: 'HeroMain' },
      { name: 'Hero Secondary', item: 'HeroSecondary' },
      { name: 'Hero Article', item: 'HeroArticlePage' },
      { name: 'Hero Other', item: 'HeroOther' },
      { name: 'Hero Landing', item: 'HeroLanding' },
      // Content
      { name: 'Article Content', item: 'ArticleContent' },
      { name: 'Announcement Content', item: 'AnnouncementContent' },
      // Actions
      { name: 'Action', item: 'CallToActionText' },
      { name: 'Social Icon', item: 'SocialMedia' },
      // Common
      { name: 'Button', item: 'Button' },
      { name: 'Typography', item: 'Typography' },
      // Tables
      { name: 'Display Table', item: 'Display Table' },
    ],
  });
};
