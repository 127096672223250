import { Builder } from '@builder.io/react';
import CallToActionText from '../../../components/builder/actions/CallToActionText';
import SocialMedia from '../../../components/builder/actions/SocialMedia';
import { socialInfo } from '../../../constants/info';

const registerActionInserts = () => {
  Builder.registerComponent(CallToActionText, {
    name: 'CallToActionText',
    inputs: [
      {
        name: 'title',
        type: 'string',
        defaultValue: 'Kom i gang med at drømme',
      },
      {
        name: 'text',
        type: 'string',
        defaultValue:
          'Er du klar til at sætte gang i dine egne drømme prøv vores løsning og tag det første skridt',
      },
      {
        name: 'link',
        type: 'string',
        defaultValue: socialInfo.app('sign-up'),
      },
      { name: 'label', type: 'string', defaultValue: 'Kom i gang' },
    ],
  });
  Builder.registerComponent(SocialMedia, {
    name: 'SocialMedia',
    inputs: [
      {
        name: 'size',
        type: 'number',
        defaultValue: 55,
      },
      {
        name: 'css',
        type: 'string',
      },
      {
        name: 'type',
        type: 'string',
        defaultValue: 'linkedin',
        enum: ['linkedin', 'facebook', 'instagram'],
      },
      {
        name: 'href',
        type: 'string',
      },
    ],
  });
};

export default registerActionInserts;
