import { Builder, withChildren } from '@builder.io/react';
import { Hero, defaultValues } from './Hero';

export const registerHero = () => {
  const inputField = (
    name: string,
    type: string,
    defaultValue: any,
    friendlyName?: string,
    helperText?: string,
    subFields?: any[],
    hideFromUI?: boolean,
    enumOptions?: any[],
  ) => ({
    name,
    type,
    defaultValue,
    friendlyName,
    helperText,
    subFields,
    hideFromUI,
    enum: enumOptions,
  });

  const subField = (
    name: string,
    type: string,
    defaultValue: any,
    helperText?: string,
    friendlyName?: string,
    enumOptions?: any[],
  ) => ({
    name,
    type,
    defaultValue,
    helperText,
    friendlyName,
    enum: enumOptions,
  });

  const colorOptions = [
    { label: 'None', value: 'none' },
    { label: 'Primary', value: 'bg-primary' },
    { label: 'Secondary', value: 'bg-secondary' },
    { label: 'Tertiary', value: 'bg-tertiary' },
    { label: 'Primary Light', value: 'bg-primary-light' },
    { label: 'Secondary Light', value: 'bg-secondary-light' },
    { label: 'Tertiary Light', value: 'bg-tertiary-light' },
    { label: 'Primary Dark', value: 'bg-primary-dark' },
  ];

  const RegisterHero = withChildren(Hero);

  Builder.registerComponent(RegisterHero, {
    name: 'Hero',
    inputs: [
      inputField(
        'includedChildren',
        'object',
        [{ blocks: [] }],
        undefined,
        undefined,
        [subField('blocks', 'blocks', [])],
        true,
      ),
      inputField('title', 'richText', defaultValues.title, 'Title', undefined),
      inputField('body', 'richText', defaultValues.body, 'Body', undefined),
      inputField('image', 'object', defaultValues.image, 'Image', undefined, [
        subField(
          'small',
          'string',
          defaultValues.image.small,
          'This will define the image shown on small screens.',
          'Small Screen',
          [
            { label: 'Phone', value: defaultValues.image.small },
            { label: 'Laptop', value: defaultValues.image.large },
          ],
        ),
        subField(
          'large',
          'string',
          defaultValues.image.large,
          'This will define the image shown on large screens.',
          'Large Screen',
          [
            { label: 'Phone', value: defaultValues.image.small },
            { label: 'Laptop', value: defaultValues.image.large },
          ],
        ),
        subField(
          'alt',
          'string',
          defaultValues.image,
          'Here you can define the alternative text for the images.',
          'Alternative text',
        ),
      ]),
      inputField(
        'backgroundColor',
        'enum',
        defaultValues.backgroundColor,
        'Background color',
        'Note, that the text color will automatically change with the background color.',
        undefined,
        false,
        colorOptions,
      ),
    ],
  });
};
