import * as React from 'react';
import { BuilderBlocks, BuilderElement } from '@builder.io/react';
import { LinkedInIconSquare, Text } from '@dreamplan/ui';
import { VariantProps } from 'class-variance-authority';
import clsx from 'clsx';

export type TextAndImageProps = {
  title: {
    text: string;
    size: VariantProps<typeof Text>['variant'];
  };
  subtitle?: string;
  body?: {
    text: string;
    size: VariantProps<typeof Text>['variant'];
  };
  mail?: string;
  icon?: string;
  image: {
    color?: SupportedColors;
    placement: string;
    mirror: boolean;
  };
  sectionColor?: SupportedColors;
  builderBlock?: BuilderElement;
  includedChildren?: {
    firstChild?: any[];
    secondChild?: any[];
  };
};

export const defaultValues = {
  title: { text: 'Lorem ipsum', size: 'h3' as VariantProps<typeof Text>['variant'] },
  subtitle: 'Lorem ipsum',
  body: {
    text: 'Lorem ipsum dolor sit <strong>amet</strong> consectetur adipiscing elit, sed do <strong>eiusmod</strong> tempor incididunt utlabore et dolore magna aliqua. Ut enim ad minim veniam, quis <strong>nostrud</strong> exercitation ullamcolaboris nisi ut <strong>aliquip</strong> ex eacommodo consequat.',
    size: 'large' as VariantProps<typeof Text>['variant'],
  },
  mail: 'lorem-ipsum@dolor.sit',
  icon: 'https://www.linkedin.com/',
  image: {
    color: 'bg-primary-light' as SupportedColors,
    placement: 'left',
    mirror: false,
  },
  sectionColor: 'bg-secondary' as SupportedColors,
  includedChildren: {
    firstChild: [{ item: [] }],
    secondChild: [{ item: [] }],
  },
};

const colorMap = {
  'bg-primary': 'text-on-primary',
  'bg-secondary': 'text-on-secondary',
  'bg-tertiary': 'text-on-tertiary',
  'bg-primary-light': 'text-on-primary-light',
  'bg-secondary-light': 'text-on-secondary-light',
  'bg-tertiary-light': 'text-on-tertiary-light',
  'bg-primary-dark': 'text-on-primary-dark',
} as const;
export type SupportedColors = keyof typeof colorMap;

export default function TextAndImage({
  title = defaultValues.title,
  subtitle = defaultValues.subtitle,
  body = defaultValues.body,
  mail = defaultValues.mail,
  icon = defaultValues.icon,
  image = defaultValues.image,
  sectionColor = defaultValues.sectionColor,
  includedChildren = {},
  builderBlock,
}: TextAndImageProps) {
  const { firstChild, secondChild } = includedChildren;

  return (
    <section
      className={clsx(
        'flex justify-center py-12 md:py-16',
        sectionColor ?? 'text-on-primary',
        colorMap[sectionColor] || 'text-on-primary',
      )}
    >
      <div className={clsx('flex w-10/12 max-w-screen-lg justify-center')}>
        <div className="grid w-full grid-cols-1 items-center gap-14 md:gap-24 lg:grid-cols-2">
          {/* BuilderBlock for Illustration */}
          <div
            className={clsx(
              image.color ?? '',
              image.placement === 'right' ? 'lg:order-last' : 'lg:order-first',
              image.mirror ? 'scale-x-[-1]' : '',
              'relative flex h-72 w-full max-w-md justify-center rounded-3xl',
            )}
          >
            {firstChild?.map((child: any, index: any) => (
              <BuilderBlocks
                key={index}
                parentElementId={builderBlock?.id}
                dataPath={`component.options.includedChildren.firstChild.${index}.item`}
                blocks={child.item}
                className="absolute bottom-0 h-80 w-full overflow-hidden"
              />
            ))}
          </div>
          {/* Text */}
          <div className="order-first w-full max-w-md">
            <div className="grid grid-cols-1 space-y-6">
              <div className="space-y-3">
                {/* Title */}
                <Text asHTML tag={'h3'} variant={title.size} className="leading-tight">
                  {title.text}
                </Text>
                {/* Subtitle */}
                {subtitle && (
                  <Text variant={'h4'} className="font-normal leading-none">
                    {subtitle}
                  </Text>
                )}
              </div>
              {/* Body text */}
              {body.text && (
                <div>
                  <Text
                    asHTML
                    variant={body.size}
                    className={clsx(
                      sectionColor === 'bg-tertiary' ? 'text-secondary' : 'text-gray-700',
                      'inline-block align-baseline leading-6',
                    )}
                  >
                    {body.text}
                  </Text>
                </div>
              )}
              <div className="space-y-3">
                {/* Mail */}
                {mail && (
                  <div className="size-fit">
                    <a href={`mailto:${mail}`}>
                      <Text className="text-base font-medium leading-none">{mail}</Text>
                    </a>
                  </div>
                )}
                {/* Icon */}
                {icon && (
                  <div className="size-fit">
                    <a href={icon} target="_blank" rel="noreferrer">
                      {<LinkedInIconSquare />}
                    </a>
                  </div>
                )}
              </div>
              {/* BuilderBlock */}
              <div>
                {secondChild?.map((child: any, index: any) => (
                  <BuilderBlocks
                    key={index}
                    parentElementId={builderBlock?.id}
                    dataPath={`component.options.includedChildren.secondChild.${index}.item`}
                    blocks={child.item}
                    className="size-fit"
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
