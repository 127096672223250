import { Builder } from '@builder.io/react';
import HeroArticle from '../../../components/builder/heroes/HeroArticle';
import HeroLanding from '../../../components/builder/heroes/HeroLanding';
import HeroMain from '../../../components/builder/heroes/HeroMain';
import HeroOther from '../../../components/builder/heroes/HeroOther';
import HeroSecondary from '../../../components/builder/heroes/HeroSecondary';
import { socialInfo } from '../../../constants/info';

const registerHeroInserts = () => {
  Builder.registerComponent(HeroLanding, {
    name: 'HeroLanding',
    inputs: [
      {
        name: 'contentHeader',
        type: 'string',
      },
      {
        name: 'contentSubHeader',
        type: 'string',
      },
      {
        name: 'buttonDescription',
        type: 'string',
      },
      {
        name: 'buttonLink',
        type: 'string',
      },
      {
        name: 'video',
        type: 'file',
        allowedFileTypes: ['mp4'],
      },
      {
        name: 'color',
        type: 'string',
        defaultValue: 'heroMain',
        enum: ['heroMain'],
      },
    ],
  });
  Builder.registerComponent(HeroOther, {
    name: 'HeroOther',
    inputs: [
      {
        name: 'color',
        type: 'string',
        defaultValue: 'heroMain',
        enum: ['heroMain', 'heroSecondary'],
      },
      { name: 'contentSubHeader', type: 'string', defaultValue: 'Title' },
      { name: 'contentDescription', type: 'richText', defaultValue: 'Text' },
      { name: 'imgSrc', type: 'file' },
      {
        name: 'buttonType',
        type: 'string',
        defaultValue: 'default',
        enum: ['default', 'secondary'],
      },
      { name: 'buttonLabel', type: 'string', defaultValue: 'Kom i gang' },
    ],
  });
  Builder.registerComponent(HeroMain, {
    name: 'HeroMain',
    inputs: [
      { name: 'contentSubHeader', type: 'string', defaultValue: 'Title' },
      {
        name: 'contentDescription',
        type: 'string',
        defaultValue: 'Description',
      },
      { name: 'navigation', type: 'string', defaultValue: '/illustrations/hero.svg' },
      { name: 'buttonDescription', type: 'string', defaultValue: 'Kom i gang' },
    ],
  });
  Builder.registerComponent(HeroSecondary, {
    name: 'HeroSecondary',
    inputs: [
      { name: 'contentHeader', type: 'string', defaultValue: 'artikler' },
      { name: 'contentSubHeader', type: 'string', defaultValue: 'Sub Header' },
      {
        name: 'contentDescription',
        type: 'string',
        defaultValue: 'Description',
      },
      {
        name: 'color',
        type: 'string',
        defaultValue: 'heroMain',
        enum: ['heroMain'],
      },
      { name: 'navigation', type: 'string', defaultValue: '/artikler' },
    ],
  });
  Builder.registerComponent(HeroArticle, {
    name: 'HeroArticlePage',
    inputs: [
      { name: 'contentHeader', type: 'string' },
      { name: 'contentSubHeader', type: 'string', defaultValue: 'Sub Header' },
      {
        name: 'contentDescription',
        type: 'string',
        defaultValue: 'Description',
      },
      {
        name: 'navigation',
        type: 'string',
        defaultValue: socialInfo.app('sign-up'),
      },
      { name: 'buttonDescription', type: 'string', defaultValue: 'Kom i gang' },
    ],
  });
};

export default registerHeroInserts;
