import * as React from 'react';
import { Text } from '@dreamplan/ui';
import clsx from 'clsx';
import Link from 'next/link';
import { ThumbnailType } from '../../types/commonTypes';

const ThumbnailText = (props: ThumbnailType) => {
  const { title, url, date } = props;

  return (
    <Link
      href={`${url}`}
      className={clsx(
        'flex cursor-pointer flex-col justify-start gap-y-1',
        'h-full w-full border p-6 transition-shadow hover:shadow-md',
      )}
    >
      <span className="text-xs capitalize text-slate-600">{date}</span>
      <Text
        variant={'large'}
        tag="h2"
        className="overflow-hidden text-ellipsis whitespace-nowrap py-0 font-semibold"
      >
        {title}
      </Text>
      {props.description && <Text>{props.description}</Text>}
    </Link>
  );
};

export default ThumbnailText;
