import * as React from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import Link from 'next/link';
import { IconType } from '../../types/commonTypes';

const ArrowDown = (props: IconType) => {
  const { height, className } = props;

  const smoothScroll = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    const nextSection = document.getElementById('nextSection');
    if (nextSection) {
      nextSection.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <Link href="#nextSection" aria-label="Scroll down" className={className} onClick={smoothScroll}>
      <ChevronDownIcon height={height} strokeWidth={2.11} aria-hidden={'true'} />
    </Link>
  );
};

export default ArrowDown;
