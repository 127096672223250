import {
  NEXT_PUBLIC_BUILDER_API_KEY,
  NEXT_PUBLIC_REVALIDATION,
} from '@dreamplan/envs/public/builderio';

const builderConfig: { apiKey: string; revalidation: number } = {
  apiKey: NEXT_PUBLIC_BUILDER_API_KEY,
  revalidation: parseInt(NEXT_PUBLIC_REVALIDATION, 10),
};

export default builderConfig;
