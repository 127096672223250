'use client';
import * as React from 'react';
import { VERCEL_ENV } from '@dreamplan/envs/public/vercel';
import dynamic from 'next/dynamic';

const DynamicActionToolbar = dynamic(
  () => import('@dreamplan/shared-ui/client/action-toolbar').then((e) => e.ActionToolbar),
  { ssr: false },
);

export function I18nEditorClient() {
  return (
    <>
      {VERCEL_ENV !== 'production' && (
        <DynamicActionToolbar dashboardLink={'https://dashboard-test.dreamplan.io/'} />
      )}
    </>
  );
}
