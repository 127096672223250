import { LocaleModels } from '../../types/localeTypes';
import { removeDuplicates } from '../../util/common';

export const displayTableModels = {
  types: ['articles', 'careers'],
  bundled: [],
};

export const localeModels: LocaleModels = {
  da: {
    models: {
      articles: 'artikler',
      careers: 'jobs',
      announcements: 'annoncer',
    },
  },
  en: {
    models: {
      articles: 'articles',
      careers: 'careers',
      announcements: 'announcements',
    },
  },
  es: {
    models: {
      articles: 'artículos',
      careers: 'empleos',
      announcements: 'anuncios',
    },
  },
  no: {
    models: {
      articles: 'artikler',
      careers: 'jobber',
      announcements: 'kunngjøringer',
    },
  },
};

export const modelList = removeDuplicates([...Object.values(localeModels.da.models)]);
