import { Builder } from '@builder.io/sdk';
import DisplayTable from '../../../components/builder/tables/DisplayTable';
import ReviewTable from '../../../components/builder/tables/ReviewTable';
import { locales } from '../../../constants/locales/Locales';
import { displayTableModels } from '../../../constants/locales/Models';

const registerTableInserts = () => {
  Builder.registerComponent(ReviewTable, {
    name: 'Review Table',
    inputs: [
      {
        name: 'reviews',
        type: 'list',
        subFields: [
          {
            name: 'reviewRating',
            type: 'number',
          },
          {
            name: 'reviewText',
            type: 'string',
          },
          {
            name: 'reviewAuthor',
            type: 'string',
          },
        ],
      },
    ],
  });
  Builder.registerComponent(DisplayTable, {
    name: 'Display Table',
    inputs: [
      {
        name: 'locale',
        type: 'string',
        defaultValue: locales[0],
        enum: locales,
      },
      {
        name: 'type',
        type: 'string',
        defaultValue: displayTableModels.types[0],
        enum: displayTableModels.types,
      },
    ],
  });
};

export default registerTableInserts;
